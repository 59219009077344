import { Component, Vue } from "vue-property-decorator";

@Component
export default class Cookies extends Vue {
  private acceptedCookies = !!localStorage.getItem("acceptedCookies");
  private showedCookies = false;

  private get isShowCookie() {
    return !this.acceptedCookies && this.showedCookies;
  }

  private acceptCookie() {
    this.acceptedCookies = true;
    localStorage.setItem("acceptedCookies", "1");
  }

  private mounted() {
    if (!this.acceptedCookies) {
      const showedCookieTimeoutId = window.setTimeout(() => {
        this.showedCookies = true;
      }, 3e3);

      this.$once("hook:beforeDestroy", () => {
        window.clearTimeout(showedCookieTimeoutId);
      });
    }
  }
}
